.wrapper{
    background: url('https://s.tykcdn.com/assets/pretty_squad/img/curve_bg.webp') no-repeat center top;
    background-size: 100%;
    min-height: 100vh;
    padding-top: 7.5rem;
}
main{
    position: relative;
}
.top{
    background: var(--top-bg);
    height: 44rem;
}
.bottom{
    background: var(--bottom-bg);
    height: 35rem;
    z-index: -1;
}
.grad-btm{
    background: var(--grad-btm-bg);
    background-size: 100%;
    height: 10rem;
    z-index: -1;
}
hr{
    border-color: var(--hr-bo);
}
a{
    cursor: pointer;
}
section h2{
    line-height: 1;
}
input, textarea{
    border-radius: var(--rad-lt);
    padding: .75rem 1rem;
}
input{
    height: 3rem;
}
.container-cst{width: 100%; padding: 0 1rem; margin: 0 auto}
@media only screen and (min-width: 640px){
    .container-cst{max-width: 640px; padding: 0 2rem}
}
@media only screen and (min-width: 768px){
    .container-cst{max-width: 768px; padding: 0 2rem}
}
@media only screen and (min-width: 1024px){
    .container-cst{max-width: 1024px; padding: 0 2rem}
}
@media only screen and (min-width: 1280px){
    .container-cst{max-width: 1280px; padding: 0 4rem}
}
@media only screen and (min-width: 1536px){
    .container-cst{max-width: 1280px; padding: 0 9rem}
}

.container-fld{width: 100%; padding: 0 1rem; margin: 0 auto}
@media only screen and (min-width: 640px){
    .container-fld{max-width: 768px; padding: 0 2rem}
}
@media only screen and (min-width: 768px){
    .container-fld{max-width: 1024px; padding: 0 2rem}
}
@media only screen and (min-width: 1024px){
    .container-fld{max-width: 1280px; padding: 0 2rem}
}
@media only screen and (min-width: 1280px){
    .container-fld{max-width: 1536px; padding: 0 4rem}
}
@media only screen and (min-width: 1536px){
    .container-fld{max-width: 1536px; padding: 0 9rem}
}

/*nav*/
nav{
    height: 7.5rem;
    display: flex;
    align-items: center;
    transition: 300ms;
}
nav.active, nav.bignav{
    background-color: var(--nav-bg);
    transition: background-color 150ms;
}
nav.active .nav-top{
    background-image: url('https://s.tykcdn.com/assets/pretty_squad/img/curve_bg.webp');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    height: 100%;
}
nav, .nav-top{
    transition: background-image 150ms;
}
.btn-nav{
    max-width: 2.5rem;
}
.btn-input-mbl{
    background-color: var(--nav-form-input-bg);
    background-image: url('https://s.tykcdn.com/assets/pretty_squad/icon/search.svg');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid var(--nav-form-input-bo);
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
}
.nav-logo img{
    width: 20rem;
}
.nav-list{
    color: var(--nav-list-c);
}
.nav-list a:not(:last-child){
    margin-right: 3rem;
}
.nav-form-input > input{
    background: var(--nav-form-input-bg);
    border: 1px solid var(--nav-form-input-bo);
    border-radius: 20px;
    height: 2.25rem;
}
.nav-form-input > img{
    width: 1rem;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
}
.nav-form-inner > button > svg, .nav-form-inner > button{
    width: 100%;
    max-width: 2.75rem;
    height: auto;
}
.user-header-img{
    position: relative;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/user.svg') no-repeat center center;
    background-size: cover;
}
.user-header-img img{
    position: absolute;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 1024px){
    .nav-container{
        max-width: unset;
    }
}
@media only screen and (min-width: 1024px){
    .collab-hilite .video-item-img > span{
        display: none;
    }
}
@media only screen and (max-width: 768px){
    .wrapper{
        padding-top: 11.25rem;
    }
    nav{
        height: 12.5rem;
        transition: all 150ms;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }
    nav.active, nav.active.bignav{
        height: 5.75rem;
    }
    nav.bignav{
        height: 12.5rem;
    }
    nav.active .nav-wrapper{
        padding: 0;
    }
    nav .nav-logo img{
        width: 8.5rem;
    }
    nav.active .nav-logo img{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -4rem;
        max-width: 6.5rem;
    }
    .nav-list a:not(:last-child){
        margin-right: 1.5rem;        
    }
    .nav-form{
        height: 4.75rem;
        justify-content: space-between;
    }
    nav.active .btn-nav-wrapper-top{
        display: none;
    }
    nav .btn-nav{
        position: absolute;
        top: 0;
    }
    nav.active .btn-nav{
        position: relative;
        top: unset;
        transform: unset;
    }
    nav .btn-nav-wrapper-top .btn-input-mbl{
        display: none;
    }
    nav .btn-nav-wrapper-btm{
        display: none;
    }
    nav.active .btn-nav-wrapper-btm{
        display: flex;
    }
    nav .nav-form-input.active{
        max-width: none;
    }
    nav.active .nav-form-input{
        display: none;
    }
}
@media only screen and (min-width: 768px){
    .nav-form-input{
        max-width: 21rem;
    }
}

/*mobile menu*/
.mobile-menu{
    background: var(--mobile-menu-bg);
    left: -100%;
    transition: 150ms;
    z-index: 10;
}
.mobile-menu.open{
    left: 0;
}
.mobile-menu .btn-close{
    top: 2rem;
    right: 1rem;
    border-radius: 50%;
}
.mobile-menu .btn-close svg{
    width: 2.5rem;
    height: 2.5rem;
}
.mobile-menu > ul{
    color: var(--mobile-menu-c);
    font-size: var(--fsizem);
    top: 42.5%;
}
.mobile-menu ul li div.active, .mobile-menu ul li:hover div{
    background: var(--mobile-menu-active-bg);
}
.mobile-menu > ul > li > button{
    width: 100%;
    text-align: left;
}
/*end mobile menu*/

/*notification*/
.search-notif-wrapper{
    width: calc(100% + 2rem);
    left: -1rem;
    top: 6.6rem;
    border-radius: 25px;
}
.search-notif-head{
    background: var(--notif-head-bg);
    border-radius: 25px 25px 0 0;
    border: 3px solid var(--notif-bo);
}
.search-notif-head > button{
    border-radius: 50%;
}
.search-notif-head > button > svg{
    width: 1.85rem;
}
.search-body-inner{
    background: var(--notif-bg);
    height: calc(100vh - 12.5rem);
}
.search-notif-body-head{
    background: var(--notif-bg);
}
.search-body-head{
    background: var(--notif-bg);
    color: var(--nbh-btn-c);
}
.search-notif-body-head button{
    color: var(--nbh-btn-c);
}
.search-notif-body-body{
    background: var(--notif-bg);
    height: calc(100vh - 16.1rem);
}
.notif-item:not(:last-child){
    border-bottom: 1px solid var(--notif-item-bo);
}
.new-notif{
    background: var(--new-notif-bg);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 8px;
    left: -8px;
}
.nil-left{
    width: 3.5rem;
}
.nil-left-img{
    position: relative;
    padding-bottom: 100%;
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/user_def.svg") no-repeat center center;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
}
.nil-left-img img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.nilr-time{
    font-size: var(--fsmall5);
    color: var(--nic-time-c);
}
.nilr-text{
    font-size: var(--fsize6);
    line-height: 1.1;
}
.notif-item-right button svg{
    width: 1.35rem;
}
.search-body-top-item{
    border-bottom: 1px solid var(--search-body-top-item-bo);
}
.search-body-top-item:not(:last-child), .search-body-btm-item:not(:last-child){
    margin-bottom: 1rem;
}
.sbti-text{
    font-size: var(--fsize5);
}
.search-body-btm-item-left{
    width: calc(100% - 3rem);
    font-size: var(--fsize5);
}
.sbbil-inner{
    line-height: 1.2;
}
.btn-clear-item{
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/delete.svg') no-repeat center center;
    background-size: contain;
    width: 2rem;
    height: 2rem;
}
/*end notification*/
/*end nav*/

/*breadcrumb*/
.breadcrumb{
    font-size: var(--fsize3);
    color: var(--breadcrumb-c);
}
.breadcrumb span:not(:last-child){
    margin-right: .5rem;
}
.chev-l{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/chev_up.svg") no-repeat center center;
    background-size: contain;
    width: .75rem;
    height: .75rem;
    transform: rotate(-90deg);
}
.chev-r{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/chev_up.svg") no-repeat center center;
    background-size: contain;
    width: .75rem;
    height: .75rem;
    transform: rotate(90deg);
}
/*end breadcrumb*/

/*floating btn*/
.floating-btn{
    bottom: 35.5%;
}
.floating-btn img{
    max-width: 8.5rem;
}

@media only screen and (min-width: 640px){
    .search-notif-wrapper{
        width: 200%;
        max-width: 30rem;
        top: 3.5rem;
        left: unset;
        right: 0;
    }
    .search-notif-body-head{
        border-left: 3px solid var(--notif-bo);
        border-right: 3px solid var(--notif-bo);
    }
    .search-notif-body-body, .search-body-inner{
        border: 3px solid var(--notif-bo);
        border-top: 0;
        border-radius: 0 0 25px 25px;
        height: 30rem;
    }
}
@media only screen and (max-width: 768px){
    .floating-btn img{
        max-width: 6.25rem;
    }
}
@media only screen and (max-width: 640px){
    .floating-btn{
        bottom: 30%;
    }
    .floating-btn img{
        max-width: 9.5rem;
    }
}
/*end floating btn*/

/*footer*/
footer{
    color: var(--footer-c);
}
.footer-top{
    background: url('https://s.tykcdn.com/assets/pretty_squad/img/footer_top_bg.webp') no-repeat bottom center;
    background-size: cover;
}
.footer-top-inner > div{
    color: var(--footer-top-text-c);
    font-size: var(--fsizeb);
    line-height: 1.2;
}
.footer-top-inner > ul{
    font-size: var(--fsize4);
}
.footer-top-inner > ul li:not(:last-child){
    margin-bottom: .5rem;
}
.footer-top-inner > img{
    max-width: 20rem;
}

.footer-btm{
    background: url('https://s.tykcdn.com/assets/pretty_squad/img/footer_btm_bg.webp') no-repeat top center;
    background-size: cover;
}
.footer-btm-inner > img{
    max-width: 10.5rem;
}
.footer-btm-inner > div{
    font-size: var(--fsmall1);
}
.footer-btm-inner > ul{
    font-size: var(--fsize3);
}
.footer-btm-inner > ul li:not(:last-child)::after{
    content: '|';
    margin-left: 1.5rem;
    
}
.footer-btm-inner > ul li:not(:last-child){
    margin-right: 1.5rem;
}

@media only screen and (min-width: 640px){
    .footer-btm-inner > div{
        font-size: var(--fsize3);
    }
}
/*end footer*/

/*home*/
.home-banner-bg{
    background: url('https://s.tykcdn.com/assets/pretty_squad/img/slider_bg.webp') no-repeat center center;
    background-size: cover;
    border-radius: var(--rad-lg);
    height: 80%;
    width: calc(100% - 1.25rem);
    left: 50%;
    transform: translateX(-50%);
}
.home-banner-left{
    color: var(--home-banner-left-c);
}
.home-banner-left h2{
    color: var(--h3-home-banner-c);
}
.home-cat-item img{
    border-radius: 12px;
    -webkit-box-shadow: var(--shadow_2);
    -moz-box-shadow: var(--shadow_2);
    box-shadow: var(--shadow_2);
}
.home-cat-item > div{
    font-size: var(--fsmall1);
    font-weight: 500;
    margin-top: .25rem;
    line-height: 1.1;
}
.carousel-home-banner{
    width: 90%;
    margin: 0 auto;
}
.carousel-home-banner .carousel-cell{
    width: 100%;
}
.carousel-home-banner .carousel-cell:first-child{
    left: 0 !important;
    margin-right: .75rem !important;
}
.home-banner-right-item{
    padding-bottom: 95.555555555%;
}
.carousel-home-banner .flickity-prev-next-button{
    width: 22px;
    height: 22px;
    top: 55%;
    border-radius: 0;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.carousel-home-banner .flickity-prev-next-button:active{
    transform: translateY(-50%) scale(.9925);
}
.carousel-home-banner .flickity-prev-next-button.previous{
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/prev_btn.svg') no-repeat center center;
    background-size: contain;
    left: -48px;
}
.carousel-home-banner .flickity-prev-next-button.next{
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/next_btn.svg') no-repeat center center;
    background-size: contain;
    right: -48px;
}
.carousel-home-banner .flickity-button svg{
    display: none;
}
.home-cat-mbl .carousel .last-slide{
    margin-left: .25rem;
}
.home-cat-mbl > p{
    color: var(--home-banner-cat-c);
    font-size: var(--fsize2);
}
.home-cat-mbl .home-cat-item > div{
    font-size: var(--fsmall);
    font-weight: 600;
}

.video-item{
    border-radius: var(--rad-sm);
}
.video-item-img{
    padding-bottom: 56.249748561%;
    border-radius: var(--rad-sm) var(--rad-sm) 0 0;
    overflow: hidden;
}
.video-item-img > img{
    object-fit: cover;
}
.video-item-img > span, .video-item-bottom > span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/premium.svg") no-repeat center center;
    background-size: contain;
    width: 9%;
    padding-bottom: 9%;
    top: .4rem;
    right: .75rem;
}
.video-item-img div{
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/play_icon.svg') no-repeat center center;
    background-size: contain;
    width: 22.5%;
    padding-bottom: 22.5%;
}
.video-item-bottom{
    background: var(--video-item-bottom-bg);
    border-radius: 0 0 var(--rad-sm) var(--rad-sm);
    min-height: 6.75rem;
}
label{
    color: var(--label-c);
    border-radius: 5px;
    display: inline-block;
    font-size: var(--fsmall1);
    padding: .025rem .35rem;
}
label.blue{background: var(--label-blue-bg)}
label.purple{background: var(--label-purple-bg)}
label.green{background: var(--label-green-bg)}
label.orange{background: var(--label-orange-bg)}
label.pink{background: var(--label-pink-bg)}

.video-item-title{
    min-height: 2.65rem;
}
.video-item-time{
    font-size: var(--fsmall3);
    color: var(--video-item-time-c);
}
.video-item-like{
    bottom: .5rem;
    left: .75rem;
}
.video-item-like > span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/fav_icon.svg") no-repeat center center;
    background-size: contain;
    width: 1rem;
    height: 1rem;
}
.video-item-like.liked > span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/fav_icon_c.svg") no-repeat center center;
    background-size: contain;
}
.video-item-bottom p{
    color: var(--video-item-p-c);
}
.video-item-share{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/share_btn.svg") no-repeat bottom center;
    background-size: contain;
    width: 9%;
    padding-bottom: 9%;
    bottom: .5rem;
    right: .75rem;
    max-width: 2.15rem;
}

.btn-more{
    background: var(--btn-more-bg);
    color: var(--btn-more-c);
    border: 1px solid var(--btn-more-bo);
    border-radius: 20px;
    font-size: var(--fsize3);
    max-width: 19rem;
}
.btn-more svg{
    width: 1.4rem;
    height: auto;
}

.h3-icon{
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/star.svg') no-repeat center center;
    background-size: contain;
    width: 1.85rem;
    height: 1.85rem;
}

.collab-hilite .video-item-img{
    border-radius: var(--rad-sm) var(--rad-sm) 0 0;
}
.collab-hilite .video-item-img > img{
    border-radius: var(--rad-sm) var(--rad-sm) 0 0;
}
.collab-hilite .video-item-title{
    font-size: var(--fsize2);
}
.collab-hilite .video-item-bottom p{
    font-size: var(--fsmall2);
}
.collab-hilite .video-item-like{
    left: 1rem;
}
.collab-hilite .video-item-bottom p{
    font-size: var(--fsize);
}

.carousel-collab .carousel-cell:first-child{
    margin-right: 4.25rem;
}
.carousel-collab .carousel-cell{
    margin-right: 2rem;
    width: 63%;
}
.carousel-collab .last-slide{
    margin-left: .4rem;
}
.carousel-collab label{
    font-size: var(--fsmall5);
}
.carousel-collab .video-item-title{
    font-size: var(--fsmall2);
    min-height: 2.25rem;
}
.carousel-collab .video-item-time{
    font-size: var(--fsmall3);
}

.carousel-home-promo{
    width: 100%;
    margin: 0;
}
.carousel-home-promo .carousel-cell{
    width: 100%;
    margin: 0;
}
.carousel-home-promo .carousel-cell:first-child{
    left: 0 !important;
}

.bg-article{
    background: var(--home-article-bg);
    width: 55%;
    height: 28rem;
    top: 58%;
    transform: translateY(-50%);
}

.article > h4{
    color: var(--article-h4-c);
}
.article-left-inner{
    max-width: 32rem;
}
.article-img{
    padding-bottom: 84.970238095%;
    border-radius: var(--rad-md);
}
.article-img img{
    border-radius: var(--rad-md);
    border: 1px solid var(--article-img-bo);
}
.article-img > div{
    background: var(--article-img-grad);
    height: 40%;
}
.article-time{
    font-size: var(--fsmall1);
}

.article-list{
    list-style-type: none;
}
.article-list:not(:last-child){
    margin-bottom: 2rem;
}
.article-list-img{
    padding-bottom: 85.204081632%;
    border-radius: var(--rad-sm);
    overflow: hidden;
}
.article-list-detail > div > p{
    color: var(--article-list-detail-p-c);
    font-size: var(--fsmall1);
    line-height: 1.2;
}
.article-list-detail > button{
    color: var(--article-list-detail-a-c);
    font-size: var(--fsmall5);
}

.forum-item{
    border-radius: var(--rad-sm);
}
.forum-item-head{
    background: var(--forum-item-head-bg);
    color: var(--forum-item-head-c);
    border-radius: var(--rad-sm) var(--rad-sm) 0 0;
    font-size: var(--fsize);
    height: 3.85rem;
    line-height: 1.65;
}
.forum-item-body{
    background: var(--forum-item-body-bg);
    border-radius: 0 0 var(--rad-sm) var(--rad-sm);
    border: 1px solid var(--forum-item-bo);
}
.forum-item-body-inner{
    display: flex;
}
.forum-item-body-inner span:nth-child(1){
    width: 1.75rem;
    position: relative;
}
.forum-item-body-inner span:nth-child(2){
    width: calc(100% - 1.75rem);
}
.forum-item-body-user span:nth-child(1) > div{
    position: relative;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/user_def.svg') no-repeat center center;
    background-size: cover;
}
.forum-item-body-user span:nth-child(1) > div > img{
    position: absolute;
    height: 100%;
    object-fit: cover;
}
.forum-item-body-user span:nth-child(2){
    font-size: var(--fsize);
    color: var(--forum-item-body-user-c);
}
.forum-item-body-detail-time{
    font-size: var(--fsmall5);
    color: var(--forum-item-body-detail-time-c);
}
.forum-item-body-detail-p{
    line-height: 1.2;
    color: var(--forum-item-body-detail-p-c);
    font-size: var(--fsmall2);
}
.forum-item-body-like-right{
    font-size: var(--fsmall4);
}
.forum-item-body-like-right div:nth-child(1) span:nth-child(1){
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/fav_icon.svg") no-repeat center center;
    background-size: contain;
    width: 12px;
    height: 12px;
    margin-right: .2rem;
}
.forum-item-body-like-right div:nth-child(1).liked span:nth-child(1){
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/fav_icon_c.svg") no-repeat center center;
    background-size: contain;
}
.forum-item-body-like-right div:nth-child(2) span:nth-child(1){
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/comment_icon.svg") no-repeat center center;
    background-size: contain;
    width: 11px;
    height: 11px;
    margin-right: .2rem;
}

.connect-dot::before{
    content: '';
    position: absolute; 
    background: var(--connect-dot-c);
    width: var(--connect-dot);
    height: var(--connect-dot);
    border-radius: 50%;
    margin-left: -2px;
    top: 0;
}
.connect-dot{
    content: '';
    position: absolute; 
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--connect-dot-c);
}
.connect-dot::after{
    content: '';
    position: absolute; 
    background: var(--connect-dot-c);
    width: var(--connect-dot);
    height: var(--connect-dot);
    border-radius: 50%;
    margin-left: -2px;
    bottom: 0;
}

.instagram-corner{
    background: var(--instagram-corner-bg);
    border: 1px solid var(--instagram-corner-bo);
    border-radius: var(--rad-lg);
    padding-top: 2rem;
}
.instagram-corner-item{
    position: relative;
    padding-bottom: 100%;
}
.instagram-corner-item img{
    position: absolute;
    height: 100%;
    object-fit: cover;
}
.carousel-instagram-corner{
    margin: 0 -3.2rem;
}
.carousel-instagram-corner .carousel-cell{
    width: calc(100% - 5.5rem);
    margin-right: 2rem;
}
.carousel-instagram-corner .carousel-cell:first-child{
    left: 2.75rem !important;
    margin-right: 4.65rem !important;
}
.carousel-instagram-corner .last-slide{
    margin-left: .75rem;
}
@media only screen and (max-width: 640px){
    .instagram-corner{
        padding: 1.5rem 2.15rem;
    }
    .home-banner-wrapper{
        border-bottom: 1px solid var(--hr-bo);
    }
    section > h2, section > div > h2{
        font-size: var(--h3);
    }
    .sharing-corner{
        border-bottom: 1px solid var(--hr-bo);
    }
    .container-hr{
        padding: 0;
    }
    .forum-item-head{
        height: 2rem;
    }
}

@media only screen and (min-width: 640px){
    .home-banner-wrapper{
        background: none;
        background-size: none;
    }
    .home-banner-bg{
        border-radius: var(--rad-lg);
        height: 100%;
    }

    .trending-left{
        width: 35%;
    }
    .trending-right{
        width: 65%;
    }

    label{
        font-size: var(--fsmall4);
    }

    .trending-hilite label{
        font-size: var(--fsmall3);
    }
    .trending-hilite .video-item-title{
        font-size: var(--h4);
    }
    .trending-hilite .video-item-time{
        font-size: var(--fsmall1);
    }

    .forum-item-head{
        font-size: var(--h5);
    }
    .forum-item-body-user span:nth-child(2){
        font-size: var(--fsize4);
    }
    .forum-item-body-detail-time{
        font-size: var(--fsmall2);
    }
    .forum-item-body-detail-p{
        font-size: var(--fsize);
    }
    .forum-item-body-like-right{
        font-size: var(--fsize);
    }
    .forum-item-body-like-right div:nth-child(1) span:nth-child(1){
        width: 17px;
        height: 17px;
    }
    .forum-item-body-like-right div:nth-child(2) span:nth-child(1){
        width: 15px;
        height: 15px;
    }

    .home-banner-right .flickity-prev-next-button.previous{
        bottom: .5rem;
        right: -1rem;
        top: unset;
        left: unset;
    }
    .home-banner-right .flickity-prev-next-button.next{
        bottom: .5rem;
        right: -2.5rem;
        top: unset;
        left: unset;
    }
}
@media only screen and (min-width: 768px){
    .home-banner-right .flickity-prev-next-button.previous{
        bottom: .75rem;
        right: -2rem;
    }
    .home-banner-right .flickity-prev-next-button.next{
        bottom: .75rem;
        right: -4rem;
    }
}
@media only screen and (min-width: 1024px){
    .collab-hilite .video-item-img{
        border-radius: var(--rad-sm) 0 0 var(--rad-sm);
    }
    .collab-hilite .video-item-img > img{
        border-radius: var(--rad-sm) 0 0 var(--rad-sm);
    }
    /* .collab-hilite .video-item-img > span{
        left: 0.75rem;
        right: none;
    } */
    .collab-hilite .video-item-bottom{
        border-radius: 0 var(--rad-sm) var(--rad-sm) 0;
    }
    .collab-hilite label{
        font-size: var(--fsize1);
    }
    .collab-hilite .video-item-title{
        font-size: var(--h3);
    }
    .collab-hilite .video-item-time{
        font-size: var(--fsmall1);
    }
    .collab-hilite .video-item-like{
        left: 1.5rem;
        font-size: var(--fsmall1);
    }
}

/*login*/
.popup-backdrop{
    background: var(--popup-backdrop-bg);
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
}
.popup-content{
    background: var(--popup-content-bg);
    width: 992px;
    max-width: calc(100% - 2rem);
    max-height: calc(100vh - 40px);
    border-radius: 30px;
}
.popup-content-sm{
    width: 525px;
}
.popup-content-md{
    width: 650px;
}
.popup-content-lg{
    width: 800px;
}
.btn-close-login{
    width: 2rem;
    height: 2rem;
    top: -.25rem;
}
.popup-top > img{
    width: 12rem;
}
.popup-top-btn button{
    color: #FFF;
    border-radius: 15px;
    height: 3.15rem;
    font-size: var(--fsize1);
}
.popup-top-btn button:not(:last-child){
    margin-bottom: .55rem;
}
.popup-top-btn .fb{
    background: #1877F1;
}
.popup-top-btn .go{
    background: #F34336;
}
.popup-top-btn img{
    width: 2.25rem;
    margin-right: .7rem;
}
.popup-top-btn iframe{
    margin: 0 auto !important;
}
.popup-top-inner input{
    border: 1px solid var(--login-c);
    border-radius: 20px;
    height: 2.7rem;
    border-radius: 15px;
}
.btn-popup-login{
    background: var(--login-c);
    color: var(--login-bg);
    border-radius: 20px;
    height: 2.5rem;
    font-size: var(--fsize2);
}
.popup-btm-content{
    background: var(--popup-btm-content-bg) !important;
    margin-top: 1.35rem !important;
}
.popup-btm-content.hidden{
    display: none !important;
}
.payment-option-sub{
    font-size: var(--fsize5);
    color: var(--login-c);
}
.payment-option > hr{
    margin: 1.25rem -1.2rem 1.15rem;
}
.payment-option-li-sub{
    font-size: var(--fsmall2);
}
.btn-popup-pay{
    background: var(--login-c);
    color: var(--login-bg);
    border-radius: 20px;
    height: 2.25rem;
    font-size: var(--fsmall1);
    max-width: 15rem;
}
.btn-popup-back svg{
    width: 1.4rem;
    height: auto;
    transform: rotate(180deg);
    margin-right: .5rem;
    margin-left: -.25rem;
}
.payment-option-lg{
    max-width: 375px;
}
.payment-option-lg button{
    max-width: 33.33%;
}
.payment-option-lg-btm{
    max-width: 405px;
}
.payment-option-lg .profile-join-btn-price{
    font-size: var(--fsmall4);
    height: 1.45rem;
    max-width: 7rem;
    margin-top: -.725rem;
}
.btn-payment-option{
    background: var(--profile-price-bg);
    color: var(--profile-price-c);
    border-radius: 20px;
    height: 2rem;
    max-width: 15.5rem;
    font-size: var(--fsmall1);
}
.btn-payment-option svg{
    margin-right: -1rem;
    width: 1.25rem;
}
.btn-payment-option-back svg{
    margin-left: -1rem;
    margin-right: 1rem;
    transform: rotate(180deg);
}
.payment-option-lg .profile-join-btn img.large{
    max-height: 5rem;
}
.payment-option-lg .profile-join-btn img.medium{
    max-height: 3.5rem;
}
.payment-option-lg .profile-join-btn img.small{
    max-height: 2.5rem;
}
.payment-option-lg .profile-join-btn{
    border-radius: 15px;   
}
.payment-option-lg-btm .profile-join-btn img.large{
    max-height: 4.5rem;
}
.payment-option-lg-btm .profile-join-btn img.medium{
    max-height: 3rem;
}
.payment-option-lg-btm .profile-join-btn img.small{
    max-height: 2rem;
}
.payment-option-lg-btm .profile-join-btn-price{
    font-size: var(--fsmall7);
    height: 1.2rem;
    max-width: 5.5rem;
    margin-top: -.6rem;
}

.carousel-payment{
    margin: 0 -1.2rem;
}
.carousel-payment .carousel-cell{
    width: 38.5%;
}
.carousel-payment .last-slide{
    margin-left: .25rem;
}
.carousel-payment .profile-join-btn-price{
    font-size: var(--fsmall4);
    height: 1.45rem;
    max-width: 7rem;
    margin-top: -.725rem;
}

@media only screen and (max-width: 768px){
    .popup-content{
        margin: 0 20px;
        max-height: calc(100vh - 160px);
        width: 100%;
    }
}
@media only screen and (min-width: 768px){
    .popup-top{
        width: 45%;
    }
    .popup-btm{
        width: 55%;
    }
    .popup-top-btn button:not(:last-child){
        margin-bottom: unset;
        margin-right: .5rem;
    }
    .btn-popup-login{
        max-width: 13.5rem;
    }
    .payment-option-sub{
        font-size: var(--h3);
    }
    .popup-btm-content-wrapper{
        background: var(--popup-btm-content-bg);
        border: 1px solid var(--profile-bo);
        border-radius: 25px;
    }
    .popup-btm-content{
        background: var(--login-bg) !important;
    }
}
@media only screen and (max-width: 640px){
    .popup-content{
        max-height: calc(100vh - 30px);
        border-radius: 15px;
    }
    .popup-btm-content{
        border: none !important;
    }
}
/*end login*/
/*end home*/

/*forum*/
/*forum category*/
/*filter*/
.filter-head{
    background: var(--filter-topic-bg);
    color: var(--filter-topic-c);
    border: 1px solid var(--filter-topic-c);
    border-radius: 8px;
    min-width: 18rem;
    height: 2.75rem;
    font-size: var(--fsize7);
}
.filter-head > span:nth-child(2){
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/chev_up.svg") no-repeat center center;
    background-size: 100%;
    width: .75rem;
    height: .75rem;
    transform: rotate(180deg);
}
.filter-body-wrapper{
    max-width: 18rem;
}
.filter-body{
    background: var(--filter-topic-bg);
    color: var(--filter-topic-c);
    border: 1px solid var(--filter-topic-c);
    border-radius: 0 0 8px 8px;
    max-width: 18rem;
    margin-top: -1px;
    font-size: var(--fsize7);
}
.filter-item{
    height: 2.75rem;
    line-height: 1.2;
    cursor: pointer;
}
.filter-item:first-child{
    display: none;
}
.filter-item:hover{
    background: var(--filter-topic-c);
    color: var(--filter-topic-bg);
}
/*end filter*/

.btn-new-topic{
    background: var(--btn-new-topic-bg);
    color: var(--btn-new-topic-c);
    border-radius: 30px;
    font-size: var(--fsize5);
}
.btn-new-topic img{
    width: 2rem;
    height: 2rem;
}

@media only screen and (min-width: 640px){
    .btn-new-topic{
        font-size: var(--fsize);
    }
}
@media only screen and (max-width: 640px){
    .filter-body{
        width: unset;
        max-width: unset;
        min-width: 12rem;
        border-radius: 8px; 
        margin-top: 5px;
        right: 0;    
    }
    .filter-item{
        height: unset;
    }
}
/*end forum category*/

/*forum topic*/
.box-topic-head{
    background: var(--box-topic-head-bg);
    color: var(--box-topic-head-c);
    border-radius: var(--rad-sm) var(--rad-sm) 0 0;
    font-size: var(--h5);
}
.box-topic-body{
    background: var(--box-topic-body-bg);
    border: 1px solid var(--box-topic-body-bo);
    border-radius: 0 0 var(--rad-sm) var(--rad-sm);
}
.comment-item-wrapper:not(:last-child){
    margin-bottom: 1.5rem;
}
.box-topic-body-left{
    width: 4rem;
}
.topic-user-img{
    position: relative;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: url('https://s.tykcdn.com/assets/pretty_squad/icon/user_def.svg') no-repeat center center;
    background-size: cover;
}
.topic-user-img img{
    position: absolute;
    height: 100%;
    object-fit: cover;
    border: 1px solid var(--new-topic-user-name-c);
}
.new-topic-user-img{
    width: 3.25rem;
}
.new-topic-user-name{
    font-size: var(--fsize4);
    color: var(--new-topic-user-name-c);
}
.new-topic-form > input, textarea{
    border: 1px solid var(--new-topic-input-bo);
}
.new-topic-hashtag button{
    background: var(--new-topic-hashtag-bg);
    color: var(--new-topic-hashtag-c);
    border: 1px solid var(--new-topic-hashtag-c);
    padding: .25rem .85rem;
    border-radius: var(--rad-lt);
}
.new-topic-hashtag button.active{
    background: var(--new-topic-hashtag-active-bg);
}
.btn-fst{
    background: var(--btn-fst-bg);
    color: var(--btn-fst-c);
    font-size: var(--fsize5);
    border-radius: 20px;
    height: 2.65rem;
    max-width: 20rem;
}

.trending-topic .forum-item-head{
    font-size: var(--fsize4);
    padding: .4rem 1.35rem;
    height: unset;
}
.trending-topic .forum-item-body{
    padding: 0.45rem 1rem 0.75rem 2.5rem;
}
.trending-topic .forum-item-body-inner span:nth-child(1){
    width: 1.6rem;
}
.trending-topic .forum-item-body-user span:nth-child(2){
    font-size: var(--fsmall);
}
.trending-topic .forum-item-body-detail-time{
    font-size: var(--fsmall6);
}
.trending-topic .forum-item-body-detail-p{
    font-size: var(--fsmall2);
}
.trending-topic .forum-item-body-like-right div:nth-child(1) span:nth-child(1){
    width: 11px;
    height: 11px;
}
.trending-topic .forum-item-body-like-right div:nth-child(2) span:nth-child(1){
    width: 10px;
    height: 10px;
}
.trending-topic .forum-item-body-like-right{
    font-size: var(--fsmall4);
}

@media only screen and (min-width: 640px){
    .box-topic-body-right{
        width: calc(100% - 4rem);
    }
    .box-topic-body-right .btn-fst{
        max-width: 8rem;
        height: 2.25rem;
        font-size: var(--fsize);
    }
    .new-topic-user-name{
        font-size: var(--fsize);
    }
    .new-topic-form > h6{
        font-size: var(--fsize2);
    }
}
/*end forum topic*/

/*forum detail*/
.forum-detail-time{
    color: var(--forum-detail-time-c);
    font-size: var(--fsmall1);
}
.forum-detail-item-right p{
    color: var(--forum-detail-p-c);
    font-size: var(--fsize5);
    line-height: 1.2;
}
.forum-detail-like.forum-item-body-like-right{
    font-size: var(--fsize3);
}
.forum-detail-like.forum-item-body-like-right div:nth-child(1) span:nth-child(1){
    width: 18px;
    height: 18px;
}
.forum-detail-like.forum-item-body-like-right div:nth-child(2) span:nth-child(1){
    width: 17px;
    height: 17px;
}
.box-topic-body hr{
    margin: 0 -.9rem;
}

.btn-reply{
    background: var(--btn-reply-bg);
    color: var(--btn-reply-c);
    border-radius: 20px;
}

@media only screen and (max-width: 640px){
    .forum-detail-item-cmt{
        width: calc(100% - 4rem);
    }
    .forum-detail-item-right p{
        margin-top: -12px;
    }
}
@media only screen and (min-width: 640px){
    .box-topic-body hr{
        margin: 0 -4rem 0 -2rem;
    }
}

/*comment*/
.comment-reply-left{
    width: 1.2rem;
}
.comment-reply-left .connect-dot{
    left: 3px;
}
.comment-reply-right{
    width: calc(100% - 1.2rem);
}
.comment-reply-item:not(:last-child){
    margin-bottom: 1.25rem;
}
.comment-name{
    font-size: var(--fsize4);
    color: var(--new-topic-user-name-c);
}
.comment-p{
    color: var(--forum-detail-p-c);
    font-size: var(--fsize5);
    line-height: 1.2;
}
.comment-time{
    color: var(--forum-detail-time-c);
    font-size: var(--fsmall1);
}

.reply-child > span:nth-child(1){
    width: calc(100% - 2.5rem);
}
.reply-child > span:nth-child(2){
    width: 2.5rem;
}
.reply-child textarea{
    padding: .375rem 1rem;
}
.btn-reply-child{
    background: var(--btn-fst-bg);
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    border-radius: 10px;
}
.btn-reply-child span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/share_btn_w.svg") no-repeat center center;
    background-size: 60%;
}
.btn-text{
    font-size: var(--fsize4);
    color: var(--new-topic-user-name-c);
}
@media only screen and (min-width: 640px){
    .forum-detail-item-right p{
        font-size: var(--fsize1);
    }
    .forum-detail-time{
        font-size: var(--fsmall3);
    }
    .btn-reply{
        font-size: var(--fsmall2);
    }
    .comment-name{
        font-size: var(--fsize1);
    }
    .comment-name span:nth-child(1){
        width: 1.65rem;
    }
    .comment-name span:nth-child(2){
        width: calc(100 - 1.65rem);
    }
    .comment-p{
        font-size: var(--fsmall);
    }
    .comment-time{
        font-size: var(--fsmall4);
    }
    .btn-text{
        font-size: var(--fsmall);
    }
}
/*end comment*/
/*end forum detail*/
/*forum*/

/*article*/
.article-tab-head .nav-tab{
    background: var(--article-nav-tab-bg);
    color: var(--article-nav-tab-c);
    border: 1px solid var(--article-nav-tab-c);
    border-radius: 10px;
    font-size: var(--fsmall3);
    height: 2.65rem;
    line-height: 1;
    height: 6rem;
}
.article-tab-head .nav-tab.active{
    background: var(--article-nav-tab-c);
    color: var(--article-nav-tab-bg);
}
.article-tab-head .nav-tab span{
    width: 1.85rem;
    height: 1.85rem;
}
#FreshFromKoreaTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/korea_flag.svg") no-repeat center center;
    background-size: contain;
}
#SkincareTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/skincare_icon.svg") no-repeat center center;
    background-size: contain;
}
#FashionTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/fashion_icon.svg") no-repeat center center;
    background-size: contain;
}
#HealthAndLifestyleTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/health_icon.svg") no-repeat center center;
    background-size: contain;
}
.article-item:not(:last-child){
    margin-bottom: 1.5rem;
}
.article-item-cover{
    width: 45%;
}
.article-item-img{
    padding-bottom: 84.829721362%;
    border-radius: var(--rad-sm);
    overflow: hidden;
}
.article-item-detail{
    width: 55%;
}
.article-item-detail > div > p{
    color: var(--article-detail-text-c);
    line-height: 1.2;
}
.article-item-time{
    font-size: var(--fsmall1);
}

.ol-tab-child{
    counter-reset: section;
}
.ol-tab-child li{
    background: transparent;
    color: var(--ol-tab-child-c);
    width: 1.3rem;
    height: 1.1rem;
    font-size: var(--fsmall);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 2px;
}
.ol-tab-child li.active{
    background: var(--ol-tab-child-c);
    color: var(--ol-tab-child-bg);
}
.ol-tab-child li::before{
    counter-increment: section;
    content: counter(section);
}

@media only screen and (min-width: 640px){
    .article-tab-head .nav-tab{
        border-radius: 10px;
        font-size: var(--fsize2);
        height: 6rem;
    }
    .article-item:not(:last-child){
        margin-bottom: unset;
    }
}
@media only screen and (min-width: 992px){
    .article-tab-head .nav-tab{
        border-radius: 50px;
        height: unset;
    }
}

/*article detail*/
.article-detail-top{
    border-radius: var(--rad-sm);
}
.article-detail-head h3, .article-detail-head h4, .article-detail-head .h2_2{
    line-height: 1.2;
}
.article-detail-time{
    font-size: var(--fsmall4);
}
.article-detail-body > img{
    border-radius: var(--rad-sm);
}
.article-src{
    color: var(--article-src-c);
    font-size: var(--fsmall4);
}
.article-detail-time{
    color: var(--article-detail-time-c);
}
.article-detail-text{
    color: var(--article-detail-text-c);
}
.btn-share-article{
    background: var(--btn-share-article-bg);
    color: var(--btn-share-article-c);
    border-radius: 30px;
    font-size: var(--fsize3);
    font-weight: 600;
    height: 2.85rem;
}
.article-detail-text{
    font-size: var(--fsmall);
}
.btn-share-article div{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/share_btn_w.svg") no-repeat center center;
    background-size: 60%;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: -1.25rem;
}
.hr-no-margin{
    margin: 0 -1rem;
}

@media only screen and (min-width: 640px){
    .article-detail-top{
        background: var(--article-detail-top-bg);
        border: 1px solid var(--article-detail-top-bo);
    }
    .article-detail-head{
        border-bottom: 1px solid var(--article-detail-head-bo);
    }
    .article-detail-text{
        font-size: var(--fsize2);
    }
    .btn-share-article{
        max-width: 10rem;
    }
}
/*end article detail*/
/*end article*/

/*profile*/
.profile-wrapper{
    border: 1px solid var(--profile-bo);
    background: var(--profile-bg);
    border-radius: 20px;
}
.profile-user-img{
    max-width: 8.5rem;
}
.profile-user-img > button{
    bottom: -1.15rem;
    right: -1rem;
}
.profile-user-img > button > img{
    max-width: 3.5rem;
}
.profile-user-display:not(:last-child){
    margin-bottom: 1.15rem;
}
.profile-user-display > label, .profile-user-referral > label{
    color: var(--profile-user-display-label);
    font-size: var(--fsize2);
    border-radius: 0;
    padding: 0;
}
.profile-user-display-in{
    color: var(--profile-text-c) !important;
    border-bottom: 1px solid var(--profile-text-c);
    border-radius: 0;
    width: 100%;
    height: 2.25rem;
    font-size: var(--fsize5);
    display: flex;
    align-items: center;
}
.profile-user-display-in::placeholder{
    color: var(--profile-text-c) !important;
}
.pudi-empty{
    font-weight: 300 !important;
}
.profile-user-input-edit{
    color: var(--profile-user-input-edit-c);
    font-size: var(--fsize2);
    top: 50%;
    transform: translateY(-50%);
}
.profile-user-referral{
    height: 3rem;
}
.profile-user-referral-left{
    background: var(--profile-btm-bg);
    color: var(--profile-text-c);
    border: 1px solid var(--profile-bo);
    border-radius: 20px 0 0 20px;
    width: calc(100% - 4rem);
    font-size: var(--fsize8);
}
.profile-user-referral-right{
    background: var(--btn-profile-referral-bg);
    border: 1px solid var(--profile-bo);
    width: 4rem;
    border-radius: 0 20px 20px 0;
}
.btn-profile-referral img{
    width: 1.75rem;
}

.profile-btm{
    background: var(--profile-btm-bg);
    border: 1px solid var(--profile-bo);
    border-radius: 15px;
}

.profile-join-btn-wrapper{
    max-width: 22rem;
}
.profile-join-btn-wrapper button{
    max-width: 50%;
}
.profile-join-btn{
    padding-bottom: 75.174223911%;
    background: var(--profile-price-c);
    border-radius: 20px;
    border: 2px solid var(--profile-price-bg);
}
.profile-join-btn img{
    position: absolute;
    width: 80%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    object-fit: contain;
}
.profile-join-btn img.large{
    max-height: 5.5rem;
}
.profile-join-btn img.medium{
    max-height: 4rem;
}
.profile-join-btn img.small{
    max-height: 3rem;
}
.profile-join-btn-price{
    background: var(--profile-price-bg);
    color: var(--profile-price-c);
    border-radius: 20px;
    height: 2.15rem;
    max-width: 11rem;
    margin: 0 auto;
    margin-top: -1.075rem;
}

.profile-join-detail{
    background: var(--profile-bg);
    border: 1px solid var(--profile-bo);
    border-radius: 15px;
    margin: 1.5rem auto -1px -1px;
}

.profile-join-sub{
    color: var(--profile-join-sub-c);
    font-size: var(--fsize5);
}
.profile-join-detail{
    color: var(--profile-join-detail-c);
}
.profile-join-detail-sub{
    color: var(--profile-join-detail-c);
    font-size: var(--fsize2);
    line-height: 1.1;
}
.profile-join-detail ul li{
    font-size: var(--fsize1);
    line-height: 1.2;
    display: flex;
}
.profile-join-detail ul li::before{
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    margin-right: .5rem;
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/check.svg") no-repeat center center;
    background-size: 16px;
}
.profile-join-detail ul li:not(:last-child){
    margin-bottom: .65rem;
}

.slider-cont-sub{
    font-size: var(--h4);
}
/* .slider-cont .video-item-img > span{
    display: none;
} */

.profile-joined{
    color: var(--profile-joined-c);
}
.profile-joined-sub{
    line-height: 1.2;
    font-size: var(--h5);
}
.profile-joined-box{
    border: 1px solid var(--profile-joined-c);
    border-radius: 10px;
    max-width: 26rem;
    min-height: 6rem;
    font-size: var(--fsize6);
    line-height: 1.2;
    box-shadow: 3px 4px 0px 0px var(--profile-joined-c);
    -webkit-box-shadow: 3px 4px 0px 0px var(--profile-joined-c);
    -moz-box-shadow: 3px 4px 0px 0px var(--profile-joined-c);
}
.profile-joined-note{
    font-size: var(--fsmall3);
    line-height: 1.2;
}

@media only screen and (min-width: 640px){
    .profile-join-detail-sub{
        font-size: var(--fsize6);
    }
    .profile-join-detail ul li{
        font-size: var(--fsmall4);
    }
    .profile-join-sub{
        font-size: var(--h3);
    }
    .profile-joined-sub{
        font-size: var(--h3);
    }
}
@media only screen and (max-width: 640px){
    .profile-join-detail{
        width: calc(100% + 2px);
    }
}
/*end profile*/

/*video*/
.carousel-popular-vid .carousel-cell:first-child{
    left: 0;
    margin-right: 2rem;
}
.carousel-popular-vid .carousel-cell{
    width: 100%;
    margin-right: 1rem;
}
.carousel-popular-vid .flickity-page-dots{
    bottom: -44px;
}
.carousel-popular-vid .flickity-page-dots .dot{
    background: var(--carousel-pop-vid-dot-c);
    width: 14px;
    height: 14px;
    margin: 0 3px;
    opacity: .4;
}
.carousel-popular-vid .flickity-page-dots .dot.is-selected{
    opacity: 1;
}

.video-program{
    background: var(--video-program-bg);
    border-radius: var(--rad-md);
}
.video-program-img img{
    border-radius: var(--rad-md) var(--rad-md) 0 0;
}
.video-program-text-sub{
    font-size: var(--h4);
    line-height: 1.2;
}
.video-program-text-date{
    font-size: var(--fsmall1);
}
.video-program-text-p{
    color: var(--video-program-text-p-c);
    line-height: 1.2;
}
.video-program-text-p p:not(:last-child){
    margin-bottom: 1rem;
}
.video-gen-btn{
    background: var(--video-program-text-btn-bg);
    color: var(--video-program-text-btn-c);
    border-radius: 30px;
    height: 2.85rem;
    font-size: var(--fsize3);
}
.video-gen-btn-sec{
    background: var(--video-program-text-sec-btn-bg);
    color: var(--video-program-text-sec-btn-c);
    height: 2rem;
    font-size: var(--fsmall5);
    bottom: .5rem;
    right: .75rem;
}
.video-gen-btn:not(:last-child){
    margin-bottom: 0;
    margin-right: .85rem;
}
.video-gen-btn > img{
    width: 1.35rem;
    height: 1.35rem;
}
.video-gen-btn > svg{
    width: 1.5rem;
    height: 1.5rem;
}
.video-gen-btn > button > svg{
    width: .95rem;
    height: .95rem;
    margin-left: 1.15rem;
}
.video-gen-btn-sec > button > svg{
    width: .8rem;
    height: .8rem;
    margin-left: .75rem;
}
.btn-share-inv{
    display: block;
}
.lg\:grid-cols-4 .btn-share-inv{
    display: inline-block;
}
.lg\:grid-cols-4 .video-gen-btn-sec{
    height: 1.5rem;
    font-size: var(--fsmall8);
}
.lg\:grid-cols-4 .video-gen-btn-sec > svg{
    width: 1rem;
    height: 1rem;
}
.lg\:grid-cols-4 .video-gen-btn-sec > button > svg{
    width: .6rem;
    height: .6rem;
}

.video-play-time{
    color: var(--video-play-time-c);
    font-size: var(--fsmall3);
}
.video-play-p{
    color: var(--video-program-text-p-c);
    line-height: 1.2;
}
.video-play-p p:not(:last-child){
    margin-bottom: 1rem;
}

.video-play-item-wrapper{
    border-left: 1px solid var(--video-play-item-bo);
}
.carousel-video-play{
    margin: 0;
}
.carousel-video-play .flickity-page-dots .dot{
    background: var(--carousel-pop-vid-dot-c);
    margin: 0 3px;
    opacity: .4;
}
.carousel-video-play .flickity-page-dots .dot.is-selected{
    opacity: 1;
}
.carousel-video-play .carousel-cell{
    width: 100%;
}
.carousel-video-play .carousel-cell:first-child{
    left: unset !important;
    margin-right: .5rem;
}
.video-play-item{
    background: var(--video-play-item-bg);
    border-radius: 15px;
}
.video-play-item-img{
    padding-bottom: 56.249669399%;
    border-radius: 7px;
}
.video-play-item-img img{
    border-radius: 7px;
}
.video-play-item-r label{
    font-size: var(--fsmall7);
    padding: .05rem .25rem;
}
.video-play-item-r-sub{
    font-size: var(--fsmall5);
    line-height: 1.1;
    margin-top: .1rem;
}
.video-play-item-r-btm{
    font-size: var(--fsmall8);
    color: var(--video-play-item-r-btm-c);
    margin-top: .15rem;
}
.video-play-item-r-btm .video-item-share{
    width: 1.1rem;
    height: 1.1rem;
}
.video-play-wrapper{
    width: calc(100% - 37.5%);
}
.video-play-item-wrapper{
    width: 37.5%;
}

@media only screen and (max-width: 768px) and (min-width: 640px){
    .video-gen-btn-sec{
        height: 1.25rem;
        font-size: var(--fsmall8);
    }
    .video-gen-btn-sec > svg{
        width: .9rem;
        height: .9rem;
    }
    .video-gen-btn-sec > button > svg{
        width: .6rem;
        height: .6rem;
    }
}
@media only screen and (max-width: 640px){
    .video-gen-btn:not(:last-child){
        margin-bottom: .5rem;
    }
}
@media only screen and (min-width: 640px){
    .video-tab-head .nav-tab{
        max-width: unset;
        width: 100%;
    }
    .video-program-img img{
        border-radius: 0 var(--rad-md) var(--rad-md) 0;
    }
    .video-program-text-sub{
        font-size: var(--h2_2);
    }
    .video-program-text-date{
        font-size: var(--fsmall2);
    }
    .video-play-wrapper{
        width: 100%;
    }
}
@media only screen and (max-width: 640px){
    .video-wrapper > hr{
        margin-left: -1rem;
        margin-right: -1rem;
    }
    .react-player-wrapper{
        margin: 0 -1rem;
    }
    .video-play-wrapper{
        width: 100%;
    }
}
/*end video*/

/*carousel*/
@media only screen and (min-width: 640px){
    .carousel-collab .carousel-cell{
      width: 38.5%;
    }
}
/*end carousel*/

/*lucky*/
/*home*/
.daily-quiz h5{
    color: var(--daily-quiz-c);
    line-height: 1.2;
}
.daily-quiz-outer{
    background: var(--daily-quiz-c);
    border-radius: 25px;
}
.daily-quiz-inner{
    background: var(--daily-quiz-text-bg);
    font-size: var(--fsize8);
    border-radius: 20px;
}
.daily-quiz-question{
    border: 1px solid var(--daily-quiz-c);
    border-radius: 15px;
    overflow: hidden;
}
.daily-question-img{
    position: relative;
    padding-bottom: 56.09375%;
}
.daily-question-img img{
    position: absolute;
    height: 100%;
    object-fit: cover;
}
.daily-question-text{
    font-weight: 400;
    min-height: 4.5rem;
}
.daily-quiz-answer{
    background: var(--daily-quiz-bg);
    border-radius: 15px;
    font-weight: 500;  
}
.daily-quiz-answer button{
    position: relative;
    background: var(--daily-quiz-c);
    color: var(--daily-quiz-text-bg);
    border: 2px solid var(--daily-quiz-c);
    width: 100%;
    border-radius: 10px;
    height: 4.35rem;
}
.daily-quiz-answer button:not(:last-child){
    margin-bottom: .65rem;
}
.daily-quiz-answer button span{
    display: none;
    place-items: center;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0,-50%);
    background: var(--daily-quiz-text-bg);
    color: var(--daily-quiz-correct-bg);
    border: 1px solid var(--daily-quiz-correct-bg);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}
.daily-quiz-answer button.correct.selected span{
    display: grid;
    -webkit-animation: Point .75s linear;
    -moz-animation: Point .75s linear;
    -o-animation: Point .75s linear;
    -ms-animation: Point .75s linear;
    animation: Point .75s linear;
    animation-fill-mode: forwards;
}
@-webkit-keyframes Point{
    0%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    20%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    40%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    60%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    80%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    100%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
}
@-moz-keyframes Point{
    0%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    20%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    40%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    60%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    80%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    100%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
}
@-o-keyframes Point{
    0%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    20%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    40%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    60%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    80%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    100%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
}
@-ms-keyframes Point{
    0%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    20%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    40%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    60%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    80%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    100%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
}
@keyframes Point{
    0%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    20%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    40%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    60%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
    80%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    100%{background:var(--daily-quiz-text-bg);color:var(--daily-quiz-correct-bg)}
}
.daily-quiz-answer button.correct{
    -webkit-animation: Check .75s linear;
    -moz-animation: Check .75s linear;
    -o-animation: Check .75s linear;
    -ms-animation: Check .75s linear;
    animation: Check .75s linear;
    animation-fill-mode: forwards;
}
@-webkit-keyframes Check{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
}
@-moz-keyframes Check{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
}
@-o-keyframes Check{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
}
@-ms-keyframes Check{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
}
@keyframes Check{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-correct-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-correct-bo)}
}

.daily-quiz-answer button.wrong{
    -webkit-animation: Wrong .75s linear;
    -moz-animation: Wrong .75s linear;
    -o-animation: Wrong .75s linear;
    -ms-animation: Wrong .75s linear;
    animation: Wrong .75s linear;
    animation-fill-mode: forwards;
}
@-webkit-keyframes Wrong{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
}
@-moz-keyframes Wrong{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
}
@-o-keyframes Wrong{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
}
@-ms-keyframes Wrong{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
}
@keyframes Wrong{
    0%{background:#FFF;color: #000}
    20%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    40%{background:#FFF;color: #000}
    60%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
    80%{background:#FFF;color: #000}
    100%{background:var(--daily-quiz-wrong-bg);color:var(--daily-quiz-text-bg);border-color:var(--daily-quiz-wrong-bo)}
}

.daily-quiz-popup-backdrop{
    background: var(--daily-quiz-popup-backdrop-bg);
    border-radius: 25px;
}
.daily-quiz-popup-content{
    background: var(--daily-quiz-popup-content-bg);
    color: var(--daily-quiz-popup-content-c);
    width: 475px;
    max-width: calc(100% - 2rem);
    border-radius: 20px;
}
.daily-quiz-popup-content h3{
    font-weight: 600;
    line-height: 1.1;
}
.daily-quiz-popup-content p{
    font-size: var(--fsize2);
}
.daily-quiz-block{
    border: 6px solid var(--daily-quiz-block-c);
    color: var(--daily-quiz-block-c);
    max-width: 475px;
    border-radius: 20px;
}
.daily-quiz-block h4{
    line-height: 1.15;
    margin-top: .15rem;
}

@media only screen and (max-width: 640px){
    .daily-quiz h5{
        color: var(--daily-quiz-c);
        font-size: var(--fsize3);
    }
    .daily-question-text{
        font-size: var(--fsize3);
    }
    .daily-quiz-answer button{
        height: 3.5rem;
        font-size: var(--fsize3);
    }
    .daily-quiz-answer button span{
        width: 2.25rem;
        height: 2.25rem;
        font-size: var(--fsize2);
    }
    .daily-quiz-block > h3{
        font-size: var(--fsize6);
    }
}
/*end home*/

/*profile*/
.profile-tab{
    border: 1px solid var(--profile-bo);
    margin: -1px -1px -1px 0;
    border-radius: 20px;
    overflow: hidden;
    height: calc(100% + 2px);
}
.btn-status{
    background: var(--btn-status-bg);
    color: var(--btn-status-c);
    border: 1px solid var(--btn-status-c);
    border-radius: 15px;
    max-width: 18rem;
}
.btn-logout{
    color: var(--tab-content-c);
    border: 1px solid var(--tab-content-c);
    font-size: var(--fsize1);
    font-weight: 700;
    border-radius: 40px;
    height: 2.5rem;
    text-align: center;
    width: 100%;
    max-width: 11.5rem;
}
.profile-tab-head{
    background: var(--profile-tab-head-bg);
    color: var(--profile-tab-head-c);
    height: 3rem;
}
.profile-head-coin{
    background: var(--profile-head-coin-bg);
    color: var(--profile-head-coin-c);
    border: 2px solid var(--profile-head-coin-bo);
    border-radius: 20px;
    font-size: var(--fsize6);
    font-weight: 600;
}
.profile-head-coin img{
    width: 1.4rem;
    height: 1.4rem;
    margin-right: .25rem
}
.btn-info-point{
   width: 1.4rem;
   height: 1.4rem;
   right: 1rem; 
}
.profile-body-nav-item{
    background: var(--profile-body-nav-item-bg);
    color: var(--profile-body-nav-item-c);
    font-size: var(--fsize4);
    font-weight: 500;
    height: 2.15rem;
    display: grid;
    place-items: center;
}
.profile-body-nav-item.active{
    background: var(--profile-body-nav-item-c);
    color: #FFF;
}
.profile-body-nav-item:first-child{
    border-radius: 8px 0 0 8px;
}
.profile-body-nav-item:last-child{
    border-radius: 0 8px 8px 0;
}
.tab-content-inner{
    color: var(--tab-content-c);
}
.tab-content-inner-scroll{
    max-height: 15rem;
    overflow: auto;
}
.tab-content-inner > span > button > div:nth-child(1){
    font-size: var(--fsize2);
    font-weight: 700;
    line-height: 1.1;
}
.tab-content-inner > span > button > div:nth-child(2){
    margin: .65rem 0 .75rem;
}
.tab-content-inner > span > button > div:nth-child(2) > img{
    max-width: 9rem;
    margin: 0 auto;
}
.tab-content-inner > span > button > div:nth-child(3) > div{
    font-size: var(--fsize2);
    font-weight: 700;
    line-height: 1.1;
}
.tab-content-inner > span > button > div:nth-child(3) > span{
    font-size: var(--fsize2);
    font-weight: 600;
    background: var(--tab-content-box-bg);
    border: 1px solid var(--tab-content-c);
    padding: .2rem .6rem;
    box-shadow: 2px 2px 0px 0px var(--tab-content-c);
    -webkit-box-shadow: 2px 2px 0px 0px var(--tab-content-c);
    -moz-box-shadow: 2px 2px 0px 0px var(--tab-content-c);
}

.tab-content-inner > ul{
    font-size: var(--fsize2);
    font-weight: 500;
    line-height: 1.1;
}
.tab-content-inner > ul > li{
    border-bottom: 1px solid var(--tab-content-c);
    min-height: 3.65rem;
    padding: .5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tab-content-inner > ul > li > span > label{
    background: var(--tab-content-bg);
    color: var(--tab-content-c);
    padding: .35rem .5rem;
    border-radius: 20px;
}
.tab-content-inner > ul > li > span:nth-child(1){
    width: calc(100% - 6rem);
}
.tab-content-inner > ul > li > span:nth-child(2){
    width: 5rem;
    display: flex;
    justify-content: flex-end;
}
.nav-history{
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
}
.nav-history-item{
    background: none;
    color: var(--nav-history-c);
    width: 1.35rem;
    height: 1.35rem;
    border-radius: 3px;
    display: grid;
    place-items: center;
    font-weight: 600;
    margin: 0 .15rem;
}
.nav-history-item.active{
    background: var(--nav-history-c);
    color: var(--nav-history-bg);
}

.popup-profile-gen{
    color: var(--tab-content-c);
}
.popup-poin-inner{
    background: var(--profile-btm-bg);
    border: 1px solid var(--profile-bo);
    border-radius: 15px;
}
.popup-poin-inner h3{
    font-weight: 600;
    margin-bottom: .5rem;
}
.popup-poin-inner ul{
    list-style-type: disc;
    padding-left: 1.15rem;
}
.popup-poin-inner ul li:not(:last-child){
    margin-bottom: .5rem;
}
.popup-poin-inner ul ul{
    list-style-type: circle;
}
.popup-poin-quiz, .popup-poin-draw{
    max-width: 25rem;
    border-radius: 8px;
    border: 1px solid var(--tab-content-c);
    box-shadow: 2px 5px 0px 0px var(--tab-content-c);
    -webkit-box-shadow: 2px 5px 0px 0px var(--tab-content-c);
    -moz-box-shadow: 2px 5px 0px 0px var(--tab-content-c);
}
.popup-poin-draw{
    max-width: 20rem;
}
.popup-poin-draw ul{
    list-style-type: square !important;
}
.popup-poin-draw ul li{
    margin-bottom: 0 !important;
}

.popup-redeem-inner{
    border: 1px solid var(--profile-bo);
    border-radius: 15px;
}
.popup-redeem-detail{
    background: var(--tab-content-bg);
    border-radius: 15px;
}
.popup-redeem-detail > div{
    font-size: var(--fsize4);
    font-weight: 600;
}
.popup-redeem-detail-t > span:nth-child(1), .popup-redeem-detail-r > div:nth-child(1){
    line-height: 1.1;
}
.popup-redeem-detail-t > span:nth-child(2), .popup-redeem-detail-r > div:nth-child(2){
    display: inline-grid;
    place-items: center;
    padding: .25rem .65rem;
    background: var(--tab-content-box-bg);
    border: 1px solid var(--tab-content-c);
    box-shadow: 2px 3px 0px 0px var(--tab-content-c);
    -webkit-box-shadow: 2px 3px 0px 0px var(--tab-content-c);
    -moz-box-shadow: 2px 3px 0px 0px var(--tab-content-c);
}
.popup-redeem-detail-l{
    max-width: 13rem;
    margin: 1.25rem auto;
}
.popup-redeem-detail > button{
    background: var(--tab-content-c);
    color: var(--nav-history-bg);
    font-weight: 600;
    border-radius: 20px;
    height: 2.5rem;
}

.popup-redeem-text h5{
    font-weight: 600;
}
.popup-redeem-text ul{
    list-style-type: disc;
    padding-left: 1.25rem;
}
.popup-redeem-text ul li{
    line-height: 1.15;
}
.popup-redeem-text ul li:not(:last-child){
    margin-bottom: .5rem;
}

.popup-profile-redeem-status{
    border: 1px solid var(--profile-bo);
    border-radius: 15px;
}
.popup-profile-redeem-status > h3{
    color: var(--tab-content-c);
    font-weight: 600;
    line-height: 1.1;
}
.popup-profile-redeem-status > p{
    font-size: var(--fsize8);
    color: var(--tab-content-c);
    line-height: 1.3;
}
.popup-profile-redeem-status > button{
    background: var(--tab-content-c);
    color: var(--nav-history-bg);
    font-weight: 600;
    border-radius: 20px;
    height: 2.5rem;
    max-width: 18rem;
}

@media only screen and (min-width: 640px){
    .popup-redeem-detail{
        width: 45%;
    }
    .popup-redeem-text{
        width: 55%;
    }
}
@media only screen and (max-width: 640px){
    .profile-tab{
        margin: -1px;
        border-radius: 0 0 20px 20px;
    }
    .profile-tab-head{
        height: 4rem;
    }
    .tab-content-inner > ul{
        padding-bottom: 5rem;
    }
    .profile-body-nav-item{
        font-size: var(--fsize7);
        height: 2.5rem;
    }
    .profile-body-nav-item:first-child{
        border-radius: 0;
    }
    .profile-body-nav-item:last-child{
        border-radius: 0;
    }
    .tab-content-inner > ul > li{
        align-items: flex-end;
    }

    .popup-poin-inner h3{
        font-size: var(--fsize4);
    }

    .popup-redeem-detail-l{
        max-width: 6rem;
        margin: 0 0 .85rem;
    }
    .tab-content-inner-scroll{
        max-height: 18rem;
    }

    .btn-logout{
        font-size: var(--fsize7);
        height: 3rem;
        max-width: 13.5rem;
    }
}
/*end profile*/

/*lucky wheel*/
.lucky-wrapper{
    background: var(--lucky-wrapper-bg);
    color: var(--lucky-wrapper-c);
    border-radius: 35px;
}
.lucky-nav-tab{
    background: var(--lucky-nav-tab-bg);
    color: var(--lucky-nav-tab-c);
    border: 2px solid var(--lucky-nav-tab-c);
    font-size: var(--fsize4);
    font-weight: 500;
    min-width: 10.5rem;
    text-align: center;
    padding: .15rem 0;
}
.lucky-nav-tab:first-child{
    border-radius: 8px 0 0 8px;
}
.lucky-nav-tab:last-child{
    border-radius: 0 8px 8px 0;
}
.lucky-nav-tab.active{
    background: var(--lucky-nav-tab-c);
    color: var(--lucky-nav-tab-bg);
}
.lucky-head > span{
    font-size: var(--fsizeb);
    font-weight: 500;
}
.lucky-head .react-datepicker-wrapper{
    width: unset;
}
.btn-date{
    border: 2px solid var(--btn-date-c);
    border-radius: 7px;
    padding: .175rem .75rem;
    display: flex;
    align-items: center;
}
.btn-date > img{
    width: 1.25rem;
    height: auto;
    margin-left: .65rem;
}
.lucky-head .react-datepicker-popper{
    z-index: 11;
}
.lucky-winner{
    font-size: var(--fsize4);
    font-weight: 500;
}
.lucky-winner ol{
    list-style-type: decimal;
    padding-left: 2rem;
    text-align: center;
}
.lucky-winner-head{
    background: var(--lucky-winner-head-bg);
    color: var(--lucky-winner-head-c);
    text-align: center;
    border-radius: 7px 7px 0 0;
    margin-bottom: .35rem;
    padding: .15rem 0;
}
.lucky-winner-body{
    height: calc(10*1.75rem);
    overflow-y: auto;

}
.lucky-winner-body ol li{
    height: 1.75rem;
}
.nav-lucky{
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.5rem;
}
.nav-lucky-item{
    background: none;
    color: var(--nav-lucky-item-c);
    width: 1.35rem;
    height: 1.35rem;
    border-radius: 3px;
    display: grid;
    place-items: center;
    font-weight: 600;
    margin: 0 .15rem;
}
.nav-lucky-item.active{
    background: var(--nav-lucky-item-c);
    color: var(--nav-lucky-item-bg);
}

.redeem-winner-item{
    font-size: var(--fsize4);
    font-weight: 500;
}
.redeem-winner-item:not(:last-child){
    margin-bottom: 1.35rem;
}
.redeem-winner-head > span:nth-child(2){
    background: var(--lucky-winner-head-bg);
    color: var(--lucky-winner-head-c);
    width: calc(100% + 1rem);
    border-top-right-radius: 8px;
    line-height: 1.2;
    margin-left: -1rem;
}
.redeem-winner-head > span:nth-child(1){
    width: 8.75rem;
}
.redeem-winner-body{
    margin-top: -.5rem;
}
.redeem-winner-body ol{
    list-style-type: decimal;
    padding-left: 2rem;
}

.accord-winner-item:not(:last-child){
    margin-bottom: .45rem;
}
.accord-winner-head{
    background: var(--accord-winner-head-bg);
    color: var(--accord-winner-head-c);
    margin: 0 -1.4rem;
    width: calc(100% + (2*1.4rem));
    font-size: var(--fsize4);
    font-weight: 500;
    height: 3.25rem;
}
.accord-winner-body{
    background: var(--accord-winner-head-bg);
    color: var(--accord-winner-body-c);
    margin: 0 -1.4rem;
    width: calc(100% + (2*1.4rem));
}
.accord-winner-body-inner{
    height: calc(10*1.75rem);
    overflow-y: auto;
}
.accord-winner-body ol{
    list-style-type: decimal;
    font-size: var(--fsize6);
    font-weight: 500;
}
.accord-winner-body ol li{
    height: 1.75rem;
}

.wheel-wrapper{
    position: relative;
    background: url('https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/bg.png') no-repeat center center;
    background-size: cover;
    border-radius: 35px;
}
.wheel-top-r img{
    position: absolute;
    width: 12.5%;
    top: -1.25rem;
    right: 1.5rem;
}
.wheel-bottom-l img{
    position: absolute;
    width: 16%;
    bottom: -2.25rem;
    left: 1.75rem;
}
.wheel-bottom-r img{
    position: absolute;
    width: 47.5%;
    bottom: 0;
    right: 0;
}
.wheel-bottom-r-2 img{
    position: absolute;
    width: 26%;
    bottom: -1.75rem;
    right: 4.25%;
}
.wheel-left > img, .wheel-wrapper > img{
    max-width: 20rem;
    margin: 0 auto;
}
.wheel-box-outer{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--wheel-box-outer-bg);
    max-width: calc(26rem + 1rem);
    height: calc(15rem + 2.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}
.wheel-box{
    position: relative;
    height: calc(5 * 3rem);
    padding: 0 1.25rem;
    width: 100%;
    max-width: 26rem;
    overflow: hidden;
}
.wheel-box > ul{
    position: relative;
    margin-top: -3rem;
}
.wheel-box > ul > li{
    position: absolute;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--wheel-box-c);
    font-size: var(--fsize4);
    font-weight: 500;
    left: 50%;
}
.wheel-box > ul > li:nth-child(odd){
    background: var(--wheel-list-1-bg);
}
.wheel-box > ul > li:nth-child(even){
    background: var(--wheel-list-2-bg);
}
.wheel-box > ul > li > span{
    color: var(--wheel-box-span-c);
}
.wheel-left-btn{
    background: var(--wheel-left-btn-bg);
    color: var(--wheel-left-btn-c);
    border-radius: 15px;
    font-size: var(--fsize4);
    font-weight: 500;
}
.wheel-left-btn a{
    color: var(--wheel-left-btn-a-c);
    text-decoration: underline;
}
.cst-week-datepicker .react-datepicker__week:hover{
    border-radius: 3px;
    color: #FFF;
    font-weight: bold;
    background: #216BA5;
}
.cst-week-datepicker .react-datepicker__day--selected{
    background: none !important;
    color: #000 !important;
}

.wheel-circle{
    position: relative;
    padding-bottom: 100%;
}
.wheel-circle .sc-gsTCUz{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(-45deg);
    z-index: 1;
}
.wheel-circle .sc-dlfnbm{
    background: var(--wheel-circle-bo);
    border-radius: 50%;
}
.wheel-circle .sc-bdfBwQ{
    transform: rotate(45deg);
    user-select: none;
}
.wheel-circle-inner{
    position: absolute;
    width: 60%;
    height: 60%;
    background: var(--wheel-circle-inner-bg);
    z-index: 2;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.wheel-circle button{
    position: absolute;
    width: 27.5%;
    height: 27.5%;
    background: var(--wheel-circle-btn-bg);
    border: 7px solid var(--wheel-circle-btn-bo);
    z-index: 2;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.popup-lucky .popup-backdrop{
    background: var(--popup-lucky-backdrop-bg);
}
.popup-lucky .popup-content{
    background: none;
    border-radius: 0;
    max-width: 518px;
}
.popup-lucky-box{
    position: relative;
    padding-bottom: 60.192307692%;
    background: url('https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/popup/box.png');
    background-size: contain;
}
.popup-lucky-gift{
    top: 44%;
    left: 0;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 22rem;
}
.popup-lucky-gift-img{
    position: relative;
    padding-bottom: 105.283018868%;
}
.popup-lucky-gift-img img{
    position: absolute;
    height: 100%;
    object-fit: contain;
}
.popup-lucky-detail{
    top: 50%;
    right: 3REM;
    transform: translate(0,-50%);
    color: var(--popup-lucky-detail-c);
    line-height: 1.1;
}
.popup-lucky-detail > h3{
    font-weight: 700;
}
.popup-lucky-detail > h4{
    margin-top: .5rem;
}
.popup-lucky-detail > button{
    background: var(--popup-lucky-detail-btn-bg);
    width: 100%;
    max-width: 15rem;
    border-radius: 40px;
    height: 3rem;
    font-size: var(--h4);
    font-weight: 700;
    margin-top: 1.35rem;
}

@media only screen and (max-width: 768px){
    .redeem-winner-head > span:nth-child(2){
        width: 100%;
        border-top-right-radius: 0;
        margin-left: 0;
    }
    .redeem-winner-body{
        margin-top: 0;
        background: var(--accord-winner-head-bg);
        border-radius: 0 0 15px 15px;
    }
    .redeem-winner-body-img img{
        border-radius: 15px;
        max-width: 17rem;
    }
    .redeem-winner-text-grid{
        font-size: var(--fsmall);
    }

    .wheel-wrapper{
        background: url('https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/bg_m.png') no-repeat center center;
        background-size: cover;
    }
    .wheel-left-btn{
        font-size: var(--fsize);
        border-radius: 40px;
    }
    .popup-lucky .popup-content{
        max-width: 315px;
    }
    .popup-lucky-box{
        background: url('https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/popup/box_m.png') no-repeat center center;
        background-size: cover;
        padding-bottom: 102.076677316%;
    }
    .popup-lucky-gift{
        top: -2.5rem;
        left: 50%;
        max-width: 12.5rem;
    }
    .popup-lucky-detail{
        top: calc(50% + 1rem);
        right: unset;
        transform: translate(-50%,-50%);
        left: 50%;
        width: calc(100% - 3rem);
    }
    .popup-lucky-detail > button{
        max-width: 12rem;
        height: 2.85rem;
        font-size: var(--h6);
    }
}
@media only screen and (max-width: 640px){
    .react-datepicker__week-number, .cst-week{
        color: #216BA5 !important;
    }
    .lucky-wrapper{
        border-radius: 20px;
    }
    .lucky-head > span{
        font-size: var(--fsize4);
    }

    .wheel-box{
        height: calc(5 * 3.2rem);
    }
    .wheel-box > ul{
        margin-top: -3.2rem;
    }
    .wheel-box > ul > li{
        height: 3.2rem;
    }

    .wheel-right{
        max-width: 19rem;
        margin: 1.75rem auto 0;
    }
    .wheel-circle-inner{
        width: 75%;
        height: 75%;
    }
    .wheel-circle button{
        width: 20%;
        height: 20%;
        border-width: 4px;
    }
}

@media only screen and (min-width: 1536px){
    .wheel-circle-inner{
        width: 77.5%;
        height: 77.5%;
    }
}
/*end lucky wheel*/
/*end lucky*/

/*text page*/
.wrapper-text{
    background: var(--wrapper-text-bg);
    color: var(--wrapper-text-c);
    border: 1px solid var(--wrapper-text-bo);
    border-radius: 15px;
    line-height: 1.4;
}
.wrapper-text-header{
    background: var(--wrapper-text-bg);
}
.wrapper-text h6{
    font-weight: 600;
}
.wrapper-text ol{
    list-style-position: inside;
}
.wrapper-text ol > li::marker{
    font-size: var(--fsize3);
    font-weight: 600;
}
.wrapper-text ol > li > b{
    display: inline-block;
    font-size: var(--fsize3);
    margin-bottom: .5rem;
}
.wrapper-text ol > li:not(:last-child){
    margin-bottom: 1.75rem;
}
.wrapper-text > ul > li:not(:last-child){
    margin-bottom: 1rem;
}
.wrapper-text ol ul.no-marker{
    padding-left: 0;
    list-style-type: none;
}
.wrapper-text ol ul.no-marker > li:not(:last-child){
    margin-bottom: 1.5rem;
}
.wrapper-text ol ul{
    margin-top: .25rem;
    padding-left: 3rem;
    list-style-type: lower-alpha;
}
.wrapper-text ol ul > li > b{
    display: block;
}
.wrapper-text ol ul > li:not(:last-child){
    margin-bottom: .5rem;
}
.wrapper-text ul ul > li:not(:last-child){
    margin-bottom: .35rem;
}
.last-updt{
    font-weight: 600;
    margin: 2rem 0 0 1.75rem
}
.btn-up{
    max-width: 2rem;
}

.cs-buttons button{
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: .65rem 3.5rem;
    font-size: var(--fsize5);
    width: 100%;
    max-width: 30rem;
    border: 2px solid var(--wrapper-text-c);
    border-radius: 25px;
}
.cs-buttons a:not(:last-child){
    display: block;
    margin-bottom: 1rem;
}
.cs-buttons button img{
    width: 1.5rem;
    margin-right: .85rem;
}
.cs-address{
    font-size: var(--fsize6);
    line-height: 1.3;
}

@media only screen and (max-width: 640px){
    .wrapper-text{
        max-height: calc(100vh - 15rem);
        overflow: auto;
    }
    .wrapper-text-header{
        margin: -2rem -2.5rem 0;
        width: calc(100% + 2.5rem);
    }
    .wrapper-text ol > li > b{
        display: unset;
    }
    .cs-buttons button {
        padding: .75rem 1.75rem;
        font-size: var(--fsize2);
        border: 1.5px solid var(--wrapper-text-c);
        border-radius: 7px;
        text-align: left;
        min-height: 4.7rem;
    }
    .cs-address b{
        font-size: var(--fsize2);
    }
    .cs-address p{
        font-size: var(--fsmall3);
    }
}
/*end text page*/

/*ig embed*/
.instagram-corner iframe{
    min-width: unset !important;
}
/*end ig embed*/