:root{
  --root-bg: #FAFAFA;
  --top-bg: linear-gradient(to bottom,#FFDEF9,transparent);
  --bottom-bg: linear-gradient(to top,#FFDEF9,transparent);
  --hr-bo: #FF97DF;
  --grad-btm-bg: linear-gradient(to top,#FFDEF9,#FAFAFA);

  --btn-fst-bg: #E859BC;
  --btn-fst-c: #FFF;
  
  /*==detail==*/
  --rad-lt: 10px;

  --rad-sm: 20px;
  --rad-md: 30px;
  --rad-lg: 50px;
  --connect-dot: 5px;
  /*==========*/

  /*nav*/
  --nav-bg: #FFDEF9;
  --nav-list-c: #F86BCD;
  --nav-form-input-bg: #FFF;
  --nav-form-input-bo: #FF97DF;

  --mobile-menu-bg: #904479F2;
  --mobile-menu-active-bg: #AF4990;
  --mobile-menu-c: #FFF;

  --notif-bg: #FFF;
  --notif-bo: #AE74D3;
  --nbh-btn-c: #AE74D3;
  --notif-head-bg: #F5E5FF;
  --new-notif-bg: #FF5050;
  --notif-item-bo: #D6D6D6;
  --nic-time-c: #A3A3A3;
  --search-body-top-item-bo: #AE74D3;

  --breadcrumb-c: #AE74D3;
  /*====*/

  /*footer*/
  --footer-c: #FFF;
  --footer-top-text-c: #FFF1CC;
  /*====*/

  /*home*/
  --h3-home-banner-c: #FFF1CC;
  --home-banner-left-c: #FFF;

  --home-banner-cat-c: #AE74D3;

  --label-blue-bg: #58BEFF;
  --label-purple-bg: #7267FF;
  --label-green-bg: #4EC056;
  --label-orange-bg: #FF816B;
  --label-pink-bg: #FF7AA7;
  --label-c: #FFF;
  --video-item-bottom-bg: #FFF;
  --video-item-time-c: #868686;
  --video-item-p-c: #8A8989;

  --btn-more-bg: #FF97DF;
  --btn-more-c: #FFF;
  --btn-more-bo: #FF67D0;

  --home-promo-bg: #050637;

  --home-article-bg: #FFDEF9;
  --article-h4-c: #FF97DF;
  --article-img-bo: #FFD6F8;
  --article-img-grad: linear-gradient(to top,#FFDEF9,transparent);
  --article-list-detail-p-c: #8A8989;
  --article-list-detail-a-c: #FF97DF;

  --forum-item-bo: #FF97DF;
  --forum-item-head-bg: #FF97DF;
  --forum-item-head-c: #FFF;
  --forum-item-body-bg: #FFF;
  --forum-item-body-user-c: #E859BC;
  --forum-item-body-detail-time-c: #A3A3A3;
  --forum-item-body-detail-p-c: #00000073;
  --connect-dot-c: #FF97DF;

  --instagram-corner-bg: #FFF;
  --instagram-corner-bo: #FFD6F8;

  --daily-quiz-bg: #FFD3F7;
  --daily-quiz-c: #AE74D3;
  --daily-quiz-bo: #D8A4F8;
  --daily-quiz-text-bg: #FFF;
  --daily-quiz-text-c: #000;
  --daily-quiz-correct-bg: #00D39A;
  --daily-quiz-correct-bo: #26E3B0;
  --daily-quiz-wrong-bg: #FF3B59;
  --daily-quiz-wrong-bo: #DD2A6F;
  --daily-quiz-block-c: #AE74D3;

  --daily-quiz-popup-backdrop-bg: #AE74D3CC;
  --daily-quiz-popup-content-bg: #FFF;
  --daily-quiz-popup-content-c: #AE74D3;
  /*====*/

  /*forum*/
  --filter-topic-bg: #FDE5F9;
  --filter-topic-c: #AE74D3;
  --btn-new-topic-bg: #AE74D3;
  --btn-new-topic-c: #FFF;

  --box-topic-head-bg: #FF97DF;
  --box-topic-head-c: #FFF;
  --box-topic-body-bg: #FFF;
  --box-topic-body-bo: #FF97DF;
  --new-topic-user-name-c: #E859BC;
  --new-topic-input-bo: #FF97DF;
  --new-topic-hashtag-bg: #FFF;
  --new-topic-hashtag-active-bg: #FDE5F9;
  --new-topic-hashtag-c: #AE74D3;

  --forum-detail-time-c: #A3A3A3;
  --forum-detail-p-c: #00000073;

  --btn-reply-bg: #AE74D3;
  --btn-reply-c: #FFF;

  --popup-backdrop-bg: #FF97DF70;
  --popup-content-bg: #FFF;
  --login-c: #E859BC;
  --login-bg: #FFF;
  --popup-btm-content-bg: #FBF0F9;
  /*====*/

  /*article*/
  --article-detail-top-bg: #FFF;
  --article-detail-top-bo: #FFD6F8;
  --article-detail-head-bo: #FF97DF;
  --article-src-c: #FF97DF;
  --btn-share-article-bg: #AE8DFF;
  --btn-share-article-c: #FFF;
  --article-detail-time-c: #868686;
  --article-detail-text-c: #00000073;
  --article-detail-text-c: #8A8989;

  --article-nav-tab-bg: #FDE5F9;
  --article-nav-tab-c: #AE74D3;

  --ol-tab-child-bg: #FFF;
  --ol-tab-child-c: #AE74D3;
  /*====*/

  /*profile*/
  --profile-bg: #FFF;
  --profile-bo: #FF97DF;
  --profile-btm-bg: #FBF0F9;

  --profile-price-bg: #AE74D3;
  --profile-price-c: #FFF;

  --profile-user-display-label: #000;
  --profile-text-c: #F86BCD;
  --profile-user-input-edit-c: #AE74D3;
  --btn-profile-referral-bg: #FF97DF;

  --profile-join-sub-c: #F86BCD;
  --profile-join-detail-c: #F86BCD;

  --profile-joined-c: #F86BCD;

  --btn-status-bg: #EFD6FF;
  --btn-status-c: #AE74D3;

  --profile-tab-head-bg: #FF97DF;
  --profile-tab-head-c: #FFF;

  --profile-head-coin-bg: #FDD835;
  --profile-head-coin-c: #000;
  --profile-head-coin-bo: #F39E09;

  --profile-body-nav-item-bg: #EFD6FF;
  --profile-body-nav-item-c: #AE74D3;

  --tab-content-bg: #FDE1F8;
  --tab-content-c: #F86BCD;
  --tab-content-bo: #FF97DF;
  --tab-content-box-bg: #FFF;

  --nav-history-bg: #FFF;
  --nav-history-c: #F86BCD;
  /*====*/

  /*video*/
  --carousel-pop-vid-dot-c: #ED8FDB;

  --video-program-bg: #F9C5D1;
  --video-program-text-p-c: #00000073;
  --video-program-text-btn-bg: #AE8DFF;
  --video-program-text-btn-c: #FFF;
  --video-program-text-sec-btn-bg: #F1EBFF;
  --video-program-text-sec-btn-c: #AE8DFF;

  --video-play-time-c: #868686;
  --video-play-item-bg: #FFF;
  --video-play-item-bo: #FF97DF;
  --video-play-item-r-btm-c: #8A8989;
  /*====*/

  /*lucky*/
  --lucky-wrapper-bg: radial-gradient(#93D1FF,#398CFF);
  --lucky-wrapper-c: #FFF;
  --lucky-nav-tab-bg: #398CFF;
  --lucky-nav-tab-c: #FFF;
  --lucky-winner-head-bg: #1976F7;
  --lucky-winner-head-c: #FFEB00;
  --btn-date-c: #FFF;
  --nav-lucky-item-bg: #4998FF;
  --nav-lucky-item-c: #FFF;

  --wheel-box-bg: #FFF;
  --wheel-box-c: #FFF;
  --wheel-box-outer-bg: #C06FFF;
  --wheel-box-span-c: #FFEB00;
  --wheel-list-1-bg: #B14EFF;
  --wheel-list-2-bg: #8F00FF;
  --wheel-left-btn-bg: #398CFF;
  --wheel-left-btn-c: #FFF;
  --wheel-left-btn-a-c: #FFEB00;

  --wheel-circle-inner-bg: #FFFFFF1A;
  --wheel-circle-btn-bg: #398CFF;
  --wheel-circle-btn-bo: #FFF;
  --wheel-circle-bo: #8000FF;

  --accord-winner-head-bg: #398CFF;
  --accord-winner-head-c: #FFEB00;
  --accord-winner-body-c: #FFF;
  
  --popup-lucky-backdrop-bg: #AE74D370;
  --popup-lucky-detail-c: #FFF;
  --popup-lucky-detail-btn-bg: #E859BD;
  /*====*/

  /*text page*/
  --wrapper-text-bg: #FBF0F9;
  --wrapper-text-c: #F86BCD;
  --wrapper-text-bo: #FF97DF;
  /*====*/
}

@media only screen and (max-width: 768px){
  :root{
    /*==detail==*/
    --rad-sm: 15px;
    --rad-md: 20px;
    --rad-lg: 30px;
    /*==========*/
  }
}