:root{
  --bg: linear-gradient(#FFF,#FFDEF9);
  --c1: #FF97DFCC;
  --c2: #E858BC;
  --c3: #FF97DF;
  --c4: #AE8DFF80;
  --c5: #FFF;
  --c6: #FFB5E8;
  --c7: #F86BCD;
  --width-dev: 480px;
}

.landing{
  background: var(--bg);
  max-width: var(--width-dev);
  min-height: 100vh;
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
}
.landing-bg{
  background: url("https://s.tykcdn.com/assets/pretty_squad/img/bg_l.png") no-repeat top center;
  background-size: contain;
  opacity: .24;
}
.landing-logo{
  max-width: 20rem;
  padding-top: 4rem;
}
.landing-txt{
  font-size: var(--fsmall);
  font-weight: 600;
  color: var(--c1);
  margin-top: 1.5rem;
}
.landing-box{
  background: var(--c5);
  color: var(--c3);
  border-radius: 18px;
  border: 1px solid var(--c3);
  margin: 3rem 1.35rem 0;
  padding: 2.5rem 1.5rem;
}
.landing-box h6{
  color: var(--c2);
  font-size: var(--fsize4);
  font-weight: 600;
  line-height: 1.1;
}
.landing-box ol{
  counter-reset: item;
  list-style: none;
  font-size: var(--fsize2);
}
.landing-box ol li{
  counter-increment: item;
  position: relative;
  line-height: 1.2;
  padding-left: 2.35rem;
}
.landing-box ol li:not(:last-child){
  margin-bottom: .65rem;
}
.landing-box ol li::before{
  content: counter(item);
  background: var(--c4);
  color: var(--c5);
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  font-size: var(--fsmall1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: -2.25rem;
  margin-right: .75rem;
  font-weight: 500;
}
.landing-btn{
  background: var(--c2);
  color: var(--c5);
  border: 1px solid var(--c6);
  min-width: 15rem;
  height: 3rem;
  font-size: var(--h3);
  font-weight: 500;
  border-radius: 30px;
  margin-top: 5rem;
}
.landing-btm{
  background: url("https://s.tykcdn.com/assets/pretty_squad/img/bg_btm.png") no-repeat bottom center;
  background-size: contain;
}
.landing-footer{
  max-width: var(--width-dev);
  background: var(--c5);
  color: var(--c7);
  font-size: var(--fsmall1);
  font-weight: 500;
  height: 1.9rem;
}

@media only screen and (max-width: 375px){
  .landing-logo{
    max-width: 16rem;
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 325px){
  .landing-logo{
    max-width: 8rem;
  }
  .landing-txt{
    margin-top: .5rem;
  }
  .landing-box{
    margin-top: 1rem;
    padding: 1.5rem 1.25rem;
  }
  .landing-box ol{
    font-size: var(--fsize);
  }
  .landing-box h6{
    font-size: var(--fsize2);
  }
  .landing-btn{
    min-width: 12rem;
    height: 2.5rem;
    font-size: var(--h5);
    margin-top: 2rem;
  }
  .landing-footer{
    font-size: var(--fsmall3);
    height: 1.5rem;
  }
}
@media only screen and (min-width: 992px) and (max-height: 768px){
  :root{
    --width-dev: 375px;
  }
}