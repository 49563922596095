@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

:root{
  --fsmall9: .5rem;
  --fsmall8: .55rem;
  --fsmall7: .6rem;
  --fsmall6: .65rem;
  --fsmall5: .7rem;
  --fsmall4: .75rem;
  --fsmall3: .8rem;
  --fsmall2: .85rem;
  --fsmall1: .9rem;
  --fsmall: .95rem;
  --fsize: 15px;
  --fsize1: 1.05rem;
  --fsize2: 1.1rem;
  --fsize3: 1.15rem;
  --fsize4: 1.2rem;
  --fsize5: 1.25rem;
  --fsize6: 1.3rem;
  --fsize7: 1.35rem;
  --fsize8: 1.4rem;
  --fsizeb: 2.15rem;
  --fsizem: 1.95rem;

  --h-big: 5rem;
  --h1: 4.5rem;
  --h2: 2.75rem;
  --h2_2: 2.25rem;
  --h3: 1.75rem;
  --h4: 1.55rem;
  --h5: 1.4rem;
  --h6: 1.25rem;
  --h7: 1.15rem;

  --shadow: 0px 0px 4px 0px rgba(0,0,0,.5);
  --shadow_2: 1px 1px 6px 0px rgba(0,0,0,.2);
  --text-s: 1px 3px 1px #00000029;

  --content-c: #000;
  --content-invert-c: #FFF;
  --input-c: #000;
  --input-phd-c: #D5D5D5;
}
html{
  font-size: var(--fsize);
}
#root, .root{
  margin: 0;
  padding: 0;
  background: var(--root-bg);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: var(--content-color);
}

input, textarea{
  color: var(--input-c) !important;
  font-weight: 600 !important;
}
textarea{
  resize: none;
}
input::placeholder, textarea::placeholder{
  color: var(--input-phd-c) !important;
  font-weight: 300;
}
.input, .button{
  border-width: 1px;
  border-color: #DBDBDB;
  border-radius: 4px;
}
.input{
  padding: 0 .75rem;
}
.button{
  border-color: #4C5560;
}
.button.disabled{
  color: #DBDBDB;
  border-color: #DBDBDB;
  pointer-events: none;
}
button:active{
  transform: scale(.9925);
  transform-origin: center center;
  transition: 150ms;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-abs:active{
  transform: scale(.9925) translateY(-50%);
}

h1{font-size: var(--h1)}
h2{font-size: var(--h2)}
.h2_2{font-size: var(--h2_2)}
h3{font-size: var(--h3)}
h4{font-size: var(--h4)}
h5{font-size: var(--h5)}
h6{font-size: var(--h6)}
.h7{font-size: var(--h7)}

img{
  width: 100%;
}

.carousel{
  margin: 0 -1rem;
}
.deep-carousel{
  margin: 0 -2.2rem;
}
.carousel-cell:first-child{
  left: 1rem !important;
  margin-right: 1.75rem;
}
.deep-carousel .carousel-cell:first-child{
  left: 2.2rem !important;
}
.carousel-cell{
  margin-right: .75rem;
  width: 50%;
}
.last-slide{
  margin-left: .75rem;
}
.abs-center{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@media only screen and (max-width: 640px){
  :root{
    --fsize: 14px;
  }
}

