#FreshFromKoreaTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/korea_flag.svg") no-repeat center center;
    background-size: contain;
}
#SkincareandMakeupTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/skincare_icon.svg") no-repeat center center;
    background-size: contain;
}
#FashionTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/fashion_icon.svg") no-repeat center center;
    background-size: contain;
}
#HealthandLifestyleTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/health_icon.svg") no-repeat center center;
    background-size: contain;
}
#CreatorTab div span{
    background: url("https://s.tykcdn.com/assets/pretty_squad/icon/creator_icon.svg") no-repeat center center;
    background-size: contain;
}